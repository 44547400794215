import { IState } from './types/state';

export function findScopeInStateChain(
  state: IState<unknown> | null,
  scopes: string[]
): IState<unknown> | null {
  if (!state || scopes.length === 0) return null;

  // Check if the current state's scope matches the first scope in the scopes array
  if (state.scope === scopes[0]) {
    // If we only have one scope left in the scopes array, return the state
    if (scopes.length === 1) {
      return state;
    } else {
      // If not, proceed with the state's child and the rest of the scopes
      return findScopeInStateChain(state.child, scopes.slice(1));
    }
  }

  // If the scope does not match, return null
  return null;
}

export type Widen<T> = T extends number
  ? number
  : T extends string
    ? string
    : T extends boolean
      ? boolean
      : T extends ReadonlyArray<infer U>
        ? ReadonlyArray<Widen<U>>
        : T extends object
          ? { [K in keyof T]: Widen<T[K]> }
          : never;

type ImmutablePrimitive =
  | undefined
  | null
  | boolean
  | string
  | number
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  | Function;
type ImmutableArray<T> = ReadonlyArray<Immutable<T>>;
type ImmutableMap<K, V> = ReadonlyMap<Immutable<K>, Immutable<V>>;
type ImmutableSet<T> = ReadonlySet<Immutable<T>>;
type ImmutableObject<T> = { readonly [K in keyof T]: Immutable<T[K]> };

// https://github.com/microsoft/TypeScript/issues/13923#issuecomment-557509399
export type Immutable<T> = T extends ImmutablePrimitive
  ? T
  : T extends Array<infer U>
    ? ImmutableArray<U>
    : T extends Map<infer K, infer V>
      ? ImmutableMap<K, V>
      : T extends Set<infer M>
        ? ImmutableSet<M>
        : ImmutableObject<T>;

export function spacesToDashes(str: string): string {
  return str.replace(/[^a-zA-Z0-9]/g, '-');
}

// Function to normalize emojis by removing variation selectors
export function normalizeEmoji(emojiString: string) {
  return emojiString.replace(/\uFE0F/g, ''); // Removes the variation selector-16 (U+FE0F)
}

/**
 * Recursively replaces all properties of type Date with string in a given type.
 *
 * This type utility traverses through object properties, array elements, and
 * other nested structures, converting any Date types to string.
 *
 * @template T - The input type to transform
 */
export type WithDatesAsStrings<T> = T extends Date
  ? string
  : T extends Array<infer U>
    ? Array<WithDatesAsStrings<U>>
    : T extends object
      ? { [K in keyof T]: WithDatesAsStrings<T[K]> }
      : T;

/**
 * Parses a date string in YYYY-MM-DD format and returns a Date object.
 *
 * @param {string} dateString - The date string to parse in YYYY-MM-DD format.
 * @returns {Date | null} A Date object if the string is valid, or null if it's invalid.
 */
export function parseDateYYYYMMDD(dateString: string): Date | null {
  const [year, month, day] = dateString.split('-').map(Number);

  if (year === undefined || month === undefined || day === undefined) {
    return null;
  }

  // Check if we have valid numbers
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return null;
  }

  // Create the date object (note: month is 0-indexed in JavaScript Date)
  const date = new Date(year, month - 1, day);

  // Validate that the date object represents the input correctly
  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day
  ) {
    return null;
  }

  return date;
}

export function dateToYYYYMMDD(date: Date): string {
  return date.toISOString().slice(0, 10);
}

/**
 * Returns the current date, rounded to the start of the day.
 *
 * This function creates a new Date object representing the current date and time,
 * then sets the time to 00:00:00.000 (midnight) to round it to the start of the day.
 * This is useful for date comparisons or when you need the current date without time information.
 *
 * @returns {Date} A Date object representing the current date at 00:00:00.000 local time.
 *
 * @example
 * const today = getCurrentDate();
 * console.log(today.toISOString()); // Outputs something like "2023-05-17T00:00:00.000Z"
 */
/**
 * Returns the current date in UTC, rounded to the start of the day.
 *
 * This function creates a new Date object representing the current date and time in UTC,
 * then sets the time to 00:00:00.000 (midnight) to round it to the start of the day.
 * This is useful for date comparisons or when you need the current date without time information,
 * ensuring consistency across different time zones.
 *
 * @returns {Date} A Date object representing the current UTC date at 00:00:00.000.
 *
 * @example
 * const todayUTC = getCurrentDate();
 * console.log(todayUTC.toISOString()); // Outputs something like "2023-05-17T00:00:00.000Z"
 */
export function getCurrentUTCDate(): Date {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0); // Round to start of day in UTC
  return today;
}

export function getPreviousUTCDate(date: Date): Date {
  const previous = new Date(date);
  previous.setUTCDate(previous.getUTCDate() - 1);
  return previous;
}
