import { Center, Image } from '@chakra-ui/react';
import { GameMetaData } from '../types';
import GameDetailsImage1 from './assets/GameDetailsImage1.png';
import Slide0 from './assets/howto/Slide0.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Thumbnail from './assets/thumbnail.jpg';

export default {
  name: 'Farkleberry',
  primaryAccentColor: 'Orange.Tangerine',
  secondaryAccentColor: 'Orange.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['Trivia', 'Funny', 'Competition'],
  elevatorPitch: (
    <>
      Can you pick out the <em>real</em> <br />
      answers from the fake ones?
    </>
  ),
  description: (
    <>
      Out of ten answers for a trivia category, pick out all the answers that
      you think are true.
      <Center my="24px">
        <Image
          src={GameDetailsImage1}
          alt="Stack of cards"
          width="214px"
          height="228px"
        />
      </Center>
      Earn points for every real answer you pick and lose points for every
      answer you are tricked by. Don’t get got!
    </>
  ),
  minPlayers: 1,
  quickBits: [
    {
      emoji: '⏱️',
      text: '2-5 mins',
    },
    {
      emoji: '💭',
      text: 'Trivia',
    },
    {
      emoji: '🏆',
      text: 'Compete',
    },
  ],
  howToSlides: [
    {
      text: 'Choose all answers that fit the prompt',
      img: Slide0,
    },
    {
      text: 'Don’t get tricked by the fake answers',
      img: Slide1,
    },
    {
      text: 'Get more points than your friends',
      img: Slide2,
    },
  ],
} satisfies GameMetaData;
