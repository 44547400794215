const imageAssets: Record<string, string | undefined> = import.meta.glob(
  './assets/items/*.png',
  {
    eager: true,
    import: 'default',
  }
);

export const getCosmeticSrc = (filename: string): string | undefined => {
  return imageAssets[`./assets/items/${filename}`];
};
