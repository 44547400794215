import { GameMetaData } from '../types';
import KitchenSinkLogo from './assets/logo.png';

export default {
  name: 'KitchenSink',
  primaryAccentColor: 'Blue.Magic',
  secondaryAccentColor: 'Blue.Pastel',
  thumbnailImage: KitchenSinkLogo,
  taglines: ['Tagline'],
  description: 'Coming soon...',
  elevatorPitch: 'Elevator Pitch',
  minPlayers: 1,
  howToSlides: [],
} satisfies GameMetaData;
