import { Audio, AudioOptions, playAudio } from '../audio';
import soundEffectsUrls, { SoundEffectsName } from './soundEffectsUrls';
import { isSoundEffectsMuteAtom, soundEffectsVolumeAtom } from '@/store/store';

// Cache the sound effects so that we create them only once.
const soundEffects: Partial<Record<SoundEffectsName, Audio>> = {};

// Play a sound effect by name with a specified volume.
export function playSoundEffect(
  soundName: SoundEffectsName,
  options?: AudioOptions
): Audio | undefined {
  return playAudio(
    soundEffects,
    soundEffectsUrls,
    soundEffectsVolumeAtom,
    isSoundEffectsMuteAtom,
    soundName,
    options
  );
}
