import { Surface } from '@common/Environment';

export const deploymentVersion =
  import.meta.env.VITE_DEPLOYMENT_VERSION ?? 'unknown';
export const DiscordClientId = import.meta.env.VITE_DISCORD_CLIENT_ID;
export const environment = import.meta.env.VITE_MC_ENVIRONMENT;

export const surface: Surface = window.location.hostname.includes('discord')
  ? 'discord'
  : 'web';
export const isRunningInsideDiscord = surface === 'discord';

export const isMcPlaywrightTestBot =
  navigator.userAgent.includes('mc-playwright-test');

export const BASE_URL =
  import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL;

function isDesktop() {
  const userAgent = window.navigator.userAgent;
  const platforms = [
    'Android',
    'iPhone',
    'iPod',
    'Windows Phone',
    'webOS',
    'BlackBerry',
  ];

  for (let i = 0; i < platforms.length; i++) {
    if (userAgent.indexOf(platforms[i]) > -1) {
      return false;
    }
  }

  // iPadOS >= 13 lies about being a desktop
  // See: https://stackoverflow.com/a/64559209
  const isDesktopMode_iPad =
    userAgent.includes('Macintosh') && navigator.maxTouchPoints > 2;

  if (isDesktopMode_iPad) {
    return false;
  }

  return true;
}

export const isDesktopMode = isDesktop();
export const platform = isDesktopMode ? 'desktop' : 'mobile';

const logItems = [
  ['Magic Circle', '👁️🟣👁️'],
  ['Version', deploymentVersion],
  ['Environment', environment],
  ['Surface', surface],
  ['Vite mode', import.meta.env.MODE],
  ['isRunningInsideDiscord', isRunningInsideDiscord],
  ['DiscordClientId', DiscordClientId],
  ['isMcPlaywrightTestBot', isMcPlaywrightTestBot],
];

console.log(
  logItems.map(([key, value]) => `%c${key}: %c${value}`).join('\n'),
  ...logItems.flatMap(() => ['font-weight: bold', 'font-weight: normal'])
);
