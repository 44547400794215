import { getValidPublicRoomIds } from '@common/types/room-info';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { surface, deploymentVersion } from '@/environment';
import { getCurrentRoomId } from '@/utils';

export const roomPortalAtom = atom<string | null>(null);

export const useIsInPublicRoom = () => {
  const roomPortal = useAtomValue(roomPortalAtom);
  if (surface === 'discord') {
    return roomPortal === null ? false : true;
  } else {
    const currentRoomId = getCurrentRoomId();
    const validPublicRoomIds = getValidPublicRoomIds(deploymentVersion);
    return currentRoomId && validPublicRoomIds.includes(currentRoomId);
  }
};

// Because the public room widget is a drawer on mobile and a sidebar component on desktop, the
// isPublicRoomWidgetOpen atom serves as the controller and single source of truth for the publicRoomWidget's visibility state.
const isPublicRoomWidgetOpenAtom = atom(false);

export const useIsPublicRoomWidgetOpen = () =>
  useAtomValue(isPublicRoomWidgetOpenAtom);

export const useSetIsPublicRoomWidgetOpen = () =>
  useSetAtom(isPublicRoomWidgetOpenAtom);

// Because the publicRoomWidgetButton is slideable, we need to store its position so that on mobile, the button within the drawer is correctly positioned.
// To be clear, there are TWO separate buttons that need to be synchronized on mobile: 1) the button in the sidebar component and 2) the button in the drawer.
const publicRoomWidgetButtonTopOffsetAtom = atom(0);

export const usePublicRoomWidgetButtonTopOffset = () =>
  useAtomValue(publicRoomWidgetButtonTopOffsetAtom);

export const useSetPublicRoomWidgetButtonTopOffset = () =>
  useSetAtom(publicRoomWidgetButtonTopOffsetAtom);
