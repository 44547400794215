import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createSafeMultiStyleConfigHelpers } from '../types';

const {
  defineSafePartsStyle: definePartsStyle,
  defineSafeMultiStyleConfig: defineMultiStyleConfig,
} = createSafeMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    SystemDrawer: {
      dialogContainer: {
        zIndex: 'SystemDrawer',
        backgroundColor: 'Scrim',
      },
      closeButton: {
        top: '18px',
        left: '18px',
      },
      dialog: {
        height: 'calc(100% - var(--sait))',
        backgroundColor: 'DarkPurple',
        // !important is needed for Chakra drawers with placement values of left
        // or right, as Chakra seems to set these values inline, which overrides
        // the 'top' value set here
        top: 'var(--sait) !important',
      },
    },
    PublicRoomDrawer: {
      dialogContainer: {
        zIndex: 'PublicRoomDrawer',
        backgroundColor: 'Scrim',
      },
      dialog: {
        height: 'calc(100% - var(--sait) - var(--system-header-height))',
        // !important is needed for Chakra drawers with placement values of left
        // or right, as Chakra seems to set these values inline, which overrides
        // the 'top' value set here
        top: 'calc(var(--sait) + var(--system-header-height)) !important',
      },
    },
  },
  defaultProps: {},
});
