import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createSafeMultiStyleConfigHelpers } from '../types';

const {
  defineSafePartsStyle: definePartsStyle,
  defineSafeMultiStyleConfig: defineMultiStyleConfig,
} = createSafeMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 'card',
    mx: '8px',
    p: '12px',
    display: 'flex',
    gap: '8px',
  },
  header: {
    p: 0,
  },
  body: {
    fontWeight: 500,
    p: 0,
  },
  footer: {
    px: 0,
    pb: 2,
  },
  dialogContainer: {
    // zIndex: 'Modal',
  },
  overlay: {
    backgroundColor: 'Scrim',
  },
});

// chakraaaaaaaaage
type Variants = Parameters<typeof defineMultiStyleConfig>[0]['variants'];

const variants = {
  FullScreen: {
    dialog: {
      p: 0,
      m: 0,
      height: '100%',
    },
  },
  GameScreen: {
    dialog: {
      p: 0,
      m: 0,
      // Make the content of the modal fit into the remainder of the space
      // left over, i.e. excluding the SystemHeader
      minHeight: 'unset',
      height: '100%',
    },
    // Allows interaction with systemheader behind modal
    overlay: {
      top: 'var(--system-header-height)',
    },
    // Allows interaction with systemheader behind modal
    dialogContainer: {
      top: 'var(--system-header-height)',
      height: 'calc(100% - var(--system-header-height))',
    },
  },
} satisfies Variants;

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    ...variants,
    PermissionPrompt: {
      dialog: {
        backgroundColor: 'MagicWhite',
        textColor: 'MagicBlack',
        px: '16px',
        py: '22px',
        gap: '20px',
      },
      header: {
        fontWeight: 'extrabold',
        textTransform: 'capitalize',
      },
      body: {
        fontWeight: 'demibold',
        fontSize: 'md',
      },
      footer: {
        display: 'flex',
        // justifyContent: 'space-between',
      },
    },
    FullScreenExceptPartyBarAndSystemHeader: {
      dialog: variants.GameScreen.dialog,
      overlay: {
        top: 'var(--system-header-height)',
        height:
          'calc(100% - var(--system-header-height) - var(--party-bar-height))',
      },
      dialogContainer: {
        top: 'var(--system-header-height)',
        height:
          'calc(100% - var(--system-header-height) - var(--party-bar-height))',
      },
    },
    // Covers the game screen, but not the SystemHeader
    McDefault: {
      dialog: {
        bg: 'MagicWhite',
        textColor: 'MagicBlack',
      },
      closeButton: {
        color: 'MagicWhite',
      },
    },
    Ghost: {
      dialog: {
        maxWidth: 'lg',
        p: 0,
        m: 0,
      },
      dialogContainer: {
        zIndex: 'DialogModal',
      },
      overlay: {
        backgroundColor: 'ScrimDarker',
        zIndex: 'DialogOverlay',
      },
    },
    Presentable: {
      dialog: {
        maxWidth: 'lg',
        p: 0,
        m: 0,
      },
      dialogContainer: {
        zIndex: 'PresentableModal',
        overflow: 'hidden',
      },
      overlay: {
        backgroundColor: 'ScrimDarkest',
        zIndex: 'PresentableOverlay',
      },
      closeButton: {
        zIndex: 'PresentableModal',
      },
    },
    PurchaseModal: {
      dialog: {
        maxWidth: 'lg',
        p: 0,
        m: 0,
      },
      dialogContainer: {
        zIndex: 'PurchaseModal',
      },
      overlay: {
        zIndex: 'PurchaseModal',
      },
      closeButton: {
        zIndex: 'PurchaseModal',
      },
    },
    ConnectionIssuesModal: {
      dialog: {
        bg: 'MagicWhite',
        color: 'MagicBlack',
        maxWidth: 'lg',
        p: '20px',
        mt: '100px',
      },
      dialogContainer: {
        zIndex: 'ConnectionIssuesModal',
      },
      overlay: {
        zIndex: 'ConnectionIssuesModal',
      },
      closeButton: {
        zIndex: 'ConnectionIssuesModal',
      },
    },
    ReportPlayerModal: {
      dialog: {
        maxWidth: 'xl',
      },
      dialogContainer: {
        zIndex: 'ReportPlayerModal',
      },
      overlay: {
        zIndex: 'ReportPlayerModal',
      },
      closeButton: {
        zIndex: 'ReportPlayerModal',
      },
    },
    PollModal: {
      dialog: {
        maxWidth: 'xl',
      },
      dialogContainer: {
        zIndex: 'PollModal',
      },
      overlay: {
        zIndex: 'PollModal',
      },
      closeButton: {
        zIndex: 'PollModal',
      },
    },
    AboveGameModal: {
      dialog: {
        maxWidth: 'xl',
      },
      dialogContainer: {
        zIndex: 'AboveGameModal',
      },
      overlay: {
        zIndex: 'AboveGameModal',
      },
      closeButton: {
        zIndex: 'AboveGameModal',
      },
    },
    CriticalError: {
      dialog: {
        bg: 'Red.Dark',
        textColor: 'MagicWhite',
        maxW: 'calc(min(80vw, 1000px))',
      },
      dialogContainer: {
        zIndex: 'CriticalErrorModal',
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        fontSize: 'md',
        fontWeight: 'bold',
      },
      body: {
        fontSize: 'sm',
      },
      overlay: {
        zIndex: 'CriticalErrorModalOverlay',
      },
    },
    Dialog: {
      dialog: {
        bg: 'Neutral.White',
        color: 'Neutral.Black',
      },
      dialogContainer: {
        zIndex: 'DialogModal',
      },
      header: {
        fontWeight: 'bold',
      },
      overlay: {
        zIndex: 'DialogOverlay',
      },
    },
  },
  defaultProps: {
    variant: 'McDefault',
  },
});
