import WebSocketCloseCode from '@common/WebSocketCloseCode';
import {
  CreateRoomPortalRequest,
  RoomPortalResponse,
} from '@common/types/room-info';
import { useConfirmationDialog } from '@components/ConfirmationDialog/useConfirmationDialog';
import { getDefaultStore } from 'jotai';
import posthog from 'posthog-js';
import { roomPortalAtom } from './store/store';
import { configAtom } from '@/config';
import RoomConnection from '@/connection/RoomConnection';
import { surface } from '@/environment';
import {
  discordSdkAtom,
  numPlayersAtom,
  useCloseDrawer,
  usePlayerId,
  usePlayersInDiscordActivityInstance,
} from '@/store/store';
import { sendRequest } from '@/utils';

export function useJoinPublicRoom() {
  const requestConfirm = useConfirmationDialog();
  const closeDrawer = useCloseDrawer();
  const playersInDiscordActivityInstance =
    usePlayersInDiscordActivityInstance();
  const myPlayerId = usePlayerId();
  const playersInDiscordActivityInstanceExcludingSelf =
    playersInDiscordActivityInstance.filter(
      (player) => player.id !== myPlayerId
    );

  function joinPublicRoom(publicRoomId: string) {
    if (surface === 'web') {
      RoomConnection.getInstance().disconnect(
        WebSocketCloseCode.PlayerLeftVoluntarily
      );
      location.href = `/r/${publicRoomId}`;
      return;
    } else if (surface === 'discord') {
      if (playersInDiscordActivityInstanceExcludingSelf.length > 0) {
        const playerNames = playersInDiscordActivityInstanceExcludingSelf.map(
          (player) => player.name
        );
        requestConfirm({
          title: '✋ Join as group?',
          message: `If you join this room, you'll bring ${playerNames.join(', ')} with you.`,
          okText: 'Join as group',
          cancelText: 'Cancel',
          onConfirm: () => {
            void createPortal(publicRoomId).catch(console.error);
            closeDrawer();
          },
        });
      } else {
        void createPortal(publicRoomId).catch(console.error);
        closeDrawer();
      }
    }
  }

  return joinPublicRoom;
}

export function useLeavePublicRoom() {
  const requestConfirm = useConfirmationDialog();
  const closeDrawer = useCloseDrawer();
  const playersInDiscordActivityInstance =
    usePlayersInDiscordActivityInstance();
  const myPlayerId = usePlayerId();
  const playersInDiscordActivityInstanceExcludingSelf =
    playersInDiscordActivityInstance.filter(
      (player) => player.id !== myPlayerId
    );

  function leavePublicRoom() {
    if (surface === 'web') {
      RoomConnection.getInstance().disconnect(
        WebSocketCloseCode.PlayerLeftVoluntarily
      );
      location.href = '/';
      return;
    } else if (surface === 'discord') {
      if (playersInDiscordActivityInstanceExcludingSelf.length > 0) {
        const playerNames = playersInDiscordActivityInstanceExcludingSelf.map(
          (player) => player.name
        );
        requestConfirm({
          title: '✋ Leave as group?',
          message: `If you leave this room, you'll bring ${playerNames.join(', ')} with you.`,
          okText: 'Leave as group',
          cancelText: 'Cancel',
          onConfirm: () => {
            void deletePortal().catch(console.error);
            closeDrawer();
          },
        });
      } else {
        void deletePortal().catch(console.error);
        closeDrawer();
      }
    }
  }

  return leavePublicRoom;
}

function getDiscordActivityInstanceId() {
  if (surface !== 'discord') {
    throw new Error(
      'Cannot get discord activity instance ID on non-discord surface'
    );
  }
  const { get } = getDefaultStore();
  const discordSdk = get(discordSdkAtom);
  if (!discordSdk) {
    throw new Error(
      'Could not get discord activity instance ID: no discord SDK'
    );
  }
  return discordSdk.instanceId;
}

export async function getPortal(): Promise<RoomPortalResponse> {
  const instanceId = getDiscordActivityInstanceId();
  return await sendRequest<RoomPortalResponse>(`/portal/${instanceId}`);
}

async function createPortal(publicRoomId: string) {
  const instanceId = getDiscordActivityInstanceId();
  const body: CreateRoomPortalRequest = {
    publicRoomId,
  };
  posthog.capture('UI_CreatePortalFromDiscordActivityInstanceToPublicRoom', {
    publicRoomId,
    numPlayers: getDefaultStore().get(numPlayersAtom),
  });
  return await sendRequest(`/portal/${instanceId}`, body, {
    method: 'PUT',
  });
}

async function deletePortal() {
  const { get } = getDefaultStore();
  const instanceId = get(discordSdkAtom)?.instanceId;
  if (!instanceId) {
    throw new Error('Could not create portal: no instance ID');
  }
  await sendRequest(`/portal/${instanceId}`, null, {
    method: 'DELETE',
  });
}

/**
 * Synchronizes the portal information (via the /portal/{instanceId} endpoint)
 * to the roomPortalAtom.
 *
 * @returns {Promise<boolean>} - Returns a promise that resolves to a boolean
 * indicating whether the portal information was updated (true) or not (false).
 */
export async function syncPortalToAtom(): Promise<boolean> {
  const config = getDefaultStore().get(configAtom);
  if (config.root_disablePublicRooms) {
    return false;
  }

  const { set, get } = getDefaultStore();
  const portalResponse = await getPortal();
  const currentPortal = get(roomPortalAtom);

  // If the portal hasn't changed, don't update the atom and return false
  if (portalResponse.publicRoomId === currentPortal) {
    return false;
  }
  console.log('roomPortalAtom updated to: ', portalResponse.publicRoomId);
  set(roomPortalAtom, portalResponse.publicRoomId);
  return true;
}
