import { Center, Image } from '@chakra-ui/react';
import { GameMetaData } from '../types';
import Thumbnail from './assets/Thumbnail_McMind_BackdropAndTitle.jpg';
import Slide0 from './assets/howto/Slide0.svg';
import Slide1 from './assets/howto/Slide1.svg';
import Slide2 from './assets/howto/Slide2.svg';
import Slide3 from './assets/howto/Slide3.svg';
import Slide4 from './assets/howto/Slide4.svg';
import GameDetailsImage from './assets/mcmind_gamedetails_img.png';

export default {
  name: 'Think You Can Count',
  primaryAccentColor: '#337b94',
  secondaryAccentColor: 'Blue.Pastel',
  thumbnailImage: Thumbnail,
  elevatorPitch: (
    <>
      Get on the same wavelength! <br />
      Can you play your numbers from <br />
      lowest to highest <em>without</em> speaking?
    </>
  ),
  taglines: ['Team Building', 'Challenge', 'Silent'],
  description: (
    <>
      Mime and gesture to communicate with your team as you try to figure out
      who has the next lowest number.
      <Center my="24px">
        <Image
          src={GameDetailsImage}
          alt="Stack of cards"
          width="167px"
          height="125px"
        />
      </Center>
      How far can your team go?
    </>
  ),
  minPlayers: 2,
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10m',
    },
    {
      emoji: '👥',
      text: '2+ players',
    },
    {
      emoji: '🤫',
      text: 'silent',
    },
  ],
  howToSlides: [
    {
      text: 'Each player gets secret numbers',
      img: Slide0,
    },
    {
      text: 'Without talking, the team must play numbers from lowest to highest',
      img: Slide1,
    },
    {
      text: 'Silently gesture and make faces to communicate',
      img: Slide2,
    },
    {
      text: 'Have the lowest number? Tap and hold to play',
      img: Slide3,
    },
    {
      text: 'How many levels can your team conquer?',
      img: Slide4,
    },
  ],
} satisfies GameMetaData;
