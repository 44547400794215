import { GameMetaData } from '../types';
import CoverImage from './assets/cover.png';
// import Thumbnail from './assets/thumbnail.svg';

export default {
  name: 'Last Word',
  primaryAccentColor: 'Blue.Magic',
  secondaryAccentColor: 'Blue.Pastel',
  thumbnailImage: CoverImage,
  taglines: ['Say words in category'],
  description:
    'Say words in the category without repeating or hesitating. Last player standing wins!',
  minPlayers: 2,
  howToSlides: [
    {
      text: 'Say words in the category!',
    },
  ],
  elevatorPitch: 'Brand new WORD GAME! (Work in Progress)',
} satisfies GameMetaData;
