import { McMindMessage } from './games/McMind/messages';
import { AnyMessage, ClientToServerMessage } from './types/messages';

export const noisyMessages = [
  {
    // Note: I wish we could make scopePath typesafe, but as of Nov 26, 2023
    // there's no easy way to do that (game names are not defined in common)
    // Hopefully the below 'satisfies' type check will be enough to catch
    // issues with this list becoming stale.
    scope: 'McMind',
    type: 'SetSpinnerPosition' satisfies McMindMessage['type'],
  },
];

export function isNoisyClientToServerMessage(
  message: ClientToServerMessage
): boolean {
  const scope = [...message.scopePath].pop();
  if (scope === undefined) {
    return false;
  }
  return isNoisyMessage(scope, message);
}

export function isNoisyMessage(scope: string, message: AnyMessage): boolean {
  return noisyMessages.some((noisyMessage) => {
    return message.type === noisyMessage.type && scope === noisyMessage.scope;
  });
}
