import { useCallback } from 'react';
import { RoomMessage } from '@common/games/Room/messages';
import { AnyMessage } from '@common/types/messages';
import RoomConnection from './RoomConnection';
import { useScopes } from '@/hooks';

export function useRoomConnection(): RoomConnection {
  return RoomConnection.getInstance();
}

export function useSendRoomMessage(): (message: RoomMessage) => void {
  const roomConnection = useRoomConnection();
  return useCallback(
    (message: RoomMessage) => {
      roomConnection.sendMessage({
        scopePath: ['Room'],
        ...message,
      });
    },
    [roomConnection]
  );
}

// The customScopes parameter was added to handle an edge case in useAppEffects.
// In this edge case, we want to send a LobbyMessage, but the scope there resolves only to ['Room'].
// However, we need it to resolve to ['Room', 'Lobby']. Hence, the customScopes parameter allows us to specify a custom scope path.
// In most cases though, we don't need to specify a custom scope path, so we can just use the default scope path.
export function useSendMessage<T extends AnyMessage>(
  customScopes?: string[]
): (message: T) => void {
  const roomConnection = useRoomConnection();
  const scopes = useScopes();

  return useCallback(
    (message: T): void => {
      roomConnection.sendMessage({
        scopePath: customScopes ?? scopes,
        ...message,
      });
    },
    [scopes, roomConnection, customScopes]
  );
}
