import { Center, Image } from '@chakra-ui/react';
import { GameMetaData } from '../types';
import Thumbnail from './assets/Thumbnail_Avocado_BackdropAndTitle.png';
import GameDetailsImage from './assets/avocado_gamedetails_img.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';
import Slide4 from './assets/howto/Slide4.png';
import Slide5 from './assets/howto/Slide5.png';

export default {
  name: 'The Question Game',
  isNew: false,
  primaryAccentColor: '#cc295a',
  secondaryAccentColor: 'Red.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['Close Friends', 'Inside Jokes'],
  elevatorPitch: (
    <>
      Answer silly questions with what your friend would say and bet which
      they'll choose!
    </>
  ),
  description: (
    <>
      Each round, answer a prompt. Write something funny, what you really think,
      or what you think your friend would say.
      <Center my="24px">
        <Image
          src={GameDetailsImage}
          alt="If I were a vegatable, what would I be?"
          width="219px"
          height="158px"
        />
      </Center>
      Bet what answer you think they will choose. Get points if you’re right or
      if you wrote the chosen answer. The player with the most points wins!
    </>
  ),
  quickBits: [
    {
      emoji: '⏱️',
      text: '>3 mins',
    },
    {
      emoji: '👥',
      text: '3+ players',
    },
    {
      emoji: '🎤',
      text: 'Talking',
    },
  ],
  minPlayers: 3,
  howToSlides: [
    {
      text: 'One player picks a prompt to ask',
      img: Slide1,
    },
    {
      text: 'Write answers that friend might give',
      img: Slide2,
    },
    {
      text: 'They secretly pick their favorite answer',
      img: Slide3,
    },
    {
      text: 'Guess which answer they picked',
      img: Slide4,
    },
    {
      text: 'Earn points for correct guesses or for writing the answer',
      img: Slide5,
    },
  ],
} satisfies GameMetaData;
