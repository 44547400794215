import { atom, getDefaultStore, useAtom, useAtomValue } from 'jotai';
import { useConfig } from '@/config';
import { useIsUserAuthenticated } from '@/store/store';

type AvocadoMiniInitialPage = 'write' | 'vote' | 'results' | Date;
const defaultInitialPage: AvocadoMiniInitialPage = 'write';

const isAvocadoMiniOpenAtom = atom(false);
export const avocadoMiniInitialPageAtom =
  atom<AvocadoMiniInitialPage>(defaultInitialPage);

export function setAvocadoMiniInitialPage(page: AvocadoMiniInitialPage) {
  const { set } = getDefaultStore();
  set(avocadoMiniInitialPageAtom, page);
  set(isAvocadoMiniOpenAtom, true);
}

export function useAvocadoMiniInitialPage() {
  const { set } = getDefaultStore();
  const initialPage = useAtomValue(avocadoMiniInitialPageAtom);

  function clearAvocadoMiniInitialPage() {
    return set(avocadoMiniInitialPageAtom, defaultInitialPage);
  }

  return {
    initialPage,
    clearInitialPage: clearAvocadoMiniInitialPage,
  };
}

/**
 * Custom hook for managing the open/close state of the Avocado Mini modal.
 * This hook emulates the disclosure interface but is backed by a Jotai atom.
 *
 * @returns {Object} An object containing:
 *   - isOpen: A boolean indicating whether the Avocado Mini modal is open.
 *   - onOpen: A function to open the Avocado Mini modal.
 *   - onClose: A function to close the Avocado Mini modal.
 *   - onToggle: A function to toggle the open/close state of the Avocado Mini modal.
 */
export const useAvocadoMiniDisclosure = () => {
  const [isOpen, setIsOpen] = useAtom(isAvocadoMiniOpenAtom);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen((prev) => !prev);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

export const useIsAvocadoMiniEnabled = () => {
  const isAuthenticated = useIsUserAuthenticated();
  const isEnabled = useConfig().miniAvocado_enabled;
  return isAuthenticated && isEnabled;
};
