/**
 * Enum representing custom WebSocket close codes for specific scenarios.
 * These codes are in the 4xxx range to avoid conflicts with standard close codes.
 * See: https://datatracker.ietf.org/doc/html/rfc6455#section-7.4.2
 */
enum WebSocketCloseCode {
  /** Used ONLY when reconnecting immediately afterwards */
  ReconnectInitiated = 4100,
  /** Indicates the player left the room voluntarily */
  PlayerLeftVoluntarily = 4200,
  /** Indicates the connection was superseded by a new one, such as when
   * opening the game in another browser tab or switching devices */
  ConnectionSuperseded = 4300,
  /** Indicates the heartbeat timeout expired */
  HeartbeatExpired = 4400,
  /** Indicates the player was kicked from the room by another player*/
  PlayerKicked = 4500,
  /** Indicates the player was banned from public rooms */
  PlayerBannedFromPublicRooms = 4600,
  /** Indicates a version mismatch between client and server. Generally, this
   * happens when the client is out of date (i.e. the client's version is older
   * than the server's version) */
  VersionMismatch = 4700,
  /** Indicates the server itself is outdated and is not accepting new connections */
  VersionExpired = 4710,
  /** Indicates an error occured during the initial connection handshake */
  AuthenticationFailure = 4800,
}

export const permanentlyDisconnectedCloseCodes = [
  WebSocketCloseCode.ConnectionSuperseded,
  WebSocketCloseCode.PlayerBannedFromPublicRooms,
  WebSocketCloseCode.PlayerKicked,
  WebSocketCloseCode.VersionMismatch,
  WebSocketCloseCode.VersionExpired,
  WebSocketCloseCode.AuthenticationFailure,
] as const;

export type PermanentlyDisconnectedCloseCode =
  (typeof permanentlyDisconnectedCloseCodes)[number];

export default WebSocketCloseCode;
