import { Center, Image } from '@chakra-ui/react';
import { GameMetaData } from '../types';
import Thumbnail from './assets/Thumbnail_Guava_BackdropAndTitle.png';
import GameDetailsImage1 from './assets/guava-gamedetails-image.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';

export default {
  name: 'The Picture Game',
  primaryAccentColor: 'Green.Magic',
  secondaryAccentColor: 'Green.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['Connect the Clues', 'Cooperative'],
  description: (
    <>
      Get four pictures and four words. <br />
      One person knows the secret picture and picks a word to give a clue.
      <br />
      <Center my="24px">
        <Image
          src={GameDetailsImage1}
          alt="Which picture best pairs with the word?"
          width="169px"
          height="236px"
        />
      </Center>
      The rest of the players must decipher the secret picture. Play as a team
      or compete against each other for the most points!
    </>
  ),
  elevatorPitch: (
    <>
      One person knows the secret picture. Can the rest figure it out with only
      one clue?
    </>
  ),
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10 mins',
    },
    {
      emoji: '👥',
      text: '2+ players',
    },
  ],
  minPlayers: 2,
  howToSlides: [
    {
      text: 'Get four pictures and four words',
      img: Slide1,
    },
    {
      text: 'The clue giver chooses one word to hint at the secret picture for the group',
      img: Slide2,
    },
    {
      text: 'Deduce which is the secret picture and earn points',
      img: Slide3,
    },
  ],
} satisfies GameMetaData;
