import { PlayerId } from '@common/types/player';
import { FileAsset, ImageAsset, RiveFile } from '@rive-app/canvas';
import avatarRiveFileSrc from './assets/avatarelements.riv?url';
import { ConcurrentCache } from '@/utils/ConcurrentCache';

const dynamicImageAssetNames = [
  'DiscordAvatarPlaceholder',
  'Top',
  'Mid',
  'Bottom',
] as const;

// Source: https://github.com/rive-app/rive-wasm/blob/aa03eb378be6d33865a9132bf8d98b2b57214698/js/src/rive.ts#L2798
const loadRiveFile = async (src: string): Promise<ArrayBuffer> => {
  const req = new Request(src);
  const res = await fetch(req);
  const buffer = await res.arrayBuffer();
  return buffer;
};

/**
 * Checks if the given asset name is a dynamic image asset name.
 *
 * @param {string} assetName - The name of the asset to check.
 * @returns {boolean} True if the asset name is a dynamic image asset name, false otherwise.
 */
function isDynamicImageAssetName(
  assetName: string
): assetName is DynamicImageAssetName {
  return dynamicImageAssetNames.includes(assetName as DynamicImageAssetName);
}

type DynamicImageAssetName = (typeof dynamicImageAssetNames)[number];

export type AvatarRiveFileCacheValue = {
  riveFile: RiveFile;
  imageAssets: {
    [key in DynamicImageAssetName]: ImageAsset | undefined;
  };
};

export class AvatarRiveFileCache {
  #cache: ConcurrentCache<AvatarRiveFileCacheValue>;
  private arrayBuffer: ArrayBuffer | undefined;
  private arrayBufferPromise: Promise<ArrayBuffer> | undefined;

  constructor() {
    this.#cache = new ConcurrentCache<AvatarRiveFileCacheValue>({
      lru: {
        maxSize: 16,
        onEvict: (key) => {
          console.log('[AvatarRiveFileCache] Evicting', key);
        },
      },
    });
  }

  public async getOrFetch(
    playerId: PlayerId
  ): Promise<AvatarRiveFileCacheValue> {
    // We use the playerId as the cache key
    return await this.#cache.getOrFetch(playerId, async () => {
      const riveFile = await this.createRiveFile();
      console.log('[AvatarRiveFileCache] Created RiveFile for', playerId);
      return riveFile;
    });
  }

  /**
   * Fetches the Rive file buffer if it hasn't been fetched already.
   * @throws {Error} If there's an error loading the Rive file.
   */
  async fetchBuffer(): Promise<void> {
    if (!this.arrayBufferPromise) {
      this.arrayBufferPromise = loadRiveFile(avatarRiveFileSrc);
    }
    try {
      this.arrayBuffer = await this.arrayBufferPromise;
    } catch (error) {
      this.arrayBufferPromise = undefined;
      this.arrayBuffer = undefined;
      throw error;
    }
  }

  async createRiveFile(): Promise<AvatarRiveFileCacheValue> {
    await this.fetchBuffer();
    return new Promise<AvatarRiveFileCacheValue>((resolve, reject) => {
      const imageAssets = Object.fromEntries(
        dynamicImageAssetNames.map((name) => [name, undefined])
      ) as AvatarRiveFileCacheValue['imageAssets'];

      const riveFile: RiveFile = new RiveFile({
        buffer: this.arrayBuffer,
        assetLoader: (asset: FileAsset) => {
          if (!asset.isImage) return false;
          if (isDynamicImageAssetName(asset.name)) {
            imageAssets[asset.name] = asset as ImageAsset;
            return true;
          }
          return false;
        },
        onLoad: () => {
          resolve({ riveFile, imageAssets });
        },
        onLoadError: (error) => {
          reject(error);
        },
      });
      riveFile.init().catch((error) => {
        reject(error);
      });
      // Increment the ref count so the RiveFile is not destroyed
      // Yes, this does raise a very important question of WHEN to cleanup the
      // RiveFile. One idea would be to clean it up when the player disconnects,
      // but for now, we think it's not necessary to do this.
      riveFile.getInstance();
    });
  }
}
