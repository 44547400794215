import { GameName, GameNameIncludingLobby } from './games';

export type RoomInfo = {
  roomId: string;
  selectedGame: GameName | null;
  currentGame: GameNameIncludingLobby | null;
  numPlayers: number;
};

export type CreateRoomPortalRequest = {
  publicRoomId: string;
};

export type RoomPortalResponse = {
  publicRoomId: string | null;
};

const validPublicRoomIdsPrefixes = [
  'PUBLIC1',
  'PUBLIC2',
  'PUBLIC3',
  'PUBLIC4',
  'PUBLIC5',
];

export const getValidPublicRoomIds = (deploymentVersion: string) => {
  return validPublicRoomIdsPrefixes.map(
    (prefix) => `${prefix}-${deploymentVersion.toUpperCase()}`
  );
};
