import { GameMetaData } from '../types';
import Thumbnail from './assets/honeydew-thumbnail.png';

export default {
  name: 'Photobomb',
  primaryAccentColor: 'Purple.Magic',
  secondaryAccentColor: 'Purple.Pastel',
  thumbnailImage: Thumbnail,
  taglines: ['Mind Games', 'Strategic'],
  description: (
    <>
      Pick the picture you think most will guess, but avoid bombs planted by
      other players!
    </>
  ),
  elevatorPitch: (
    <>
      Will you guide your friends to victory, or will you be the one to blow
      them up?
    </>
  ),
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10 mins',
    },
    {
      emoji: '👥',
      text: '3+ players',
    },
  ],
  minPlayers: 3,
  howToSlides: [
    {
      text: 'Each round, a player gives a hint for a picture',
      // img: Slide1,
    },
    {
      text: 'Pick the picture you think most will guess, but avoid bombs planted by other players.',
      // img: Slide2,
    },
    {
      text: 'Earn points by picking the picture with the majority of votes or bombing other players.',
      // img: Slide3,
    },
  ],
} satisfies GameMetaData;
